/**
 * lp tooltip plugin
 *
 * @version 1.1
 * @fires beforeOpen.lpTooltip
 * @fires afterOpen.lpTooltip
 * @fires beforeClose.lpTooltip
 * @fires afterClose.lpTooltip
 *
 * @listen forceClose.lpTooltip Listen on $this (to which has been applied tooltip) and $thisTooltip (floating div with tooltip content) - see variables below
 */
(function ($) {

	$.fn.lpTooltip = function (options) {

		if (!this.length) {

			return this;

		}

		// default options
		const defaults = {
			tooltip: $('#tooltip'), // tooltip element to be used
			useParentOffset: false, // determine if tooltip should use offset() or position(), depends on element parent (often is <body)
			horzAlign: 'center', // horizontal position of tooltip - options are left, center, and right
			vertAlign: 'top', // vertical position of tooltip - options are top, middle, and bottom
			arrow: false, // want an arrow on the tooltip?
			arrowSize: 15, // px size of arrow
			arrowColor: '#fff', // color of arrow - can accept any css border-color property value (hex, rgb, rgba, gradient) - can't accept more than one value though so no vendor prefixes or fallbacks
			arrowBorderWidth: 0, // border width of arrow
			arrowBorderColor: '#9a9a9a', // border color of arrow
			borderRadius: '', // border radius of tooltip
			boxShadow: {
				enabled: false, // flag to enable/disable box shadow. if false, the rest of boxShadow options below are ignored
				offsetX: '5px', // the horizontal distance of shadow
				offsetY: '5px', // the vertical distance of shadow
				blurRadius: '5px', // the blur distance
				spreadRadius: '0px', // the size of shadow
				shadowColor: 'rgba(0,0,0,0.15)' // color of shadow
			},
			screenEdgePadding: 10, // padding from side of screen to be used when tooltip needs to be moved
			offsetX: 0, // offset the position of the tooltip on the x axis - use positive or negative integers
			offsetY: 0, // offset the position of the tooltip on the y axis - use positive or negative integers
			evtType: 'hover', // event to fire tooltip - options are hover and click
			keyboardNavigationTriggerElement: null, // the element that opens the tooltip when pressing Enter and receives focus when pressing Enter on Close button.
			showCloseBtn: false, // include close button
			autoClose: true, // do not close on mouseout or clicking outside the tooltip. showCloseBtn option should be set to true to give user option to explicitly close the tooltip.
			closeOnTooltipBtnBlur: false, // close when focusing out of the button that triggers the tooltip. Only works with autoClose: true
			preOpenEvt: () => 1, // function before open
			openEvt: () => {}, // function to be run when tooltip opens
			closeEvt: () => {} // function to be run when tooltip closes
		};
		const o = $.extend(true, defaults, options); // combine defaults and passed in options
		const $window = $(window);
		const $document = $(document);
		const $html = $('html');

		/**
		 * Returns current open status for $tooltip (true if $tooltip was opened). If newStatus was passed then set new openStatus
		 * @param {jQueryObject} $tooltip
		 * @param {bool} newStatus
		 * @return {bool}
		 */
		function openStatus ($tooltip, newStatus) {

			if (newStatus !== undefined) {

				$tooltip[0].dataset.wasOpen = newStatus ? '1' : '0';

			}

			return $tooltip[0].dataset.wasOpen === '1';

		}

		function isToolTipHidden ($toolTip) {

			return parseInt($toolTip.css('opacity'), 10) === 0;

		}

		function isToolTipVisible ($toolTip) {

			return parseInt($toolTip.css('opacity'), 10) === 1;

		}

		this.each(function (ttNum) {

			const
				$this = $(this);
			const $thisTooltip = o.tooltip.length > 1 ? $(o.tooltip[ttNum]) : o.tooltip;
			$thisTooltipInner = $thisTooltip.find('.lpTooltip__inner');
			$cssContainer = $thisTooltipInner.length > 0 ? $thisTooltipInner : $thisTooltip;

			// start listening
			$this.on('forceClose.lpTooltip', ForceClose);
			$thisTooltip.on('forceClose.lpTooltip', ForceClose);

			if (isIpad()) {

				o.evtType = 'click';

			}

			$this.addClass('haslpt');
			$thisTooltip
				.addClass('lpTooltip')
				.hide();

			const $arrow = $thisTooltip.find('.lpTooltipArrow');
			const $lpTooltipArrow = !$arrow.length ? $('<div class="lpTooltipArrow"></div>').appendTo($cssContainer) : $arrow;

			if (o.boxShadow.enabled) {

				$cssContainer.css(
					'box-shadow',
					`${o.boxShadow.offsetX
					} ${
						o.boxShadow.offsetY
					} ${
						o.boxShadow.blurRadius
					} ${
						o.boxShadow.spreadRadius
					} ${
						o.boxShadow.shadowColor}`
				);

			}

			if (o.arrowBorderWidth > 0) {

				$cssContainer.css('border', `${o.arrowBorderWidth}px solid ${o.arrowBorderColor}`);

			}

			if (o.borderRadius) {

				$cssContainer.css('border-radius', o.borderRadius);

			}

			if (o.showCloseBtn) {

				$cssContainer.append('<button type="button" class="close anchorLink" aria-label="Close"></button>');

			}

			function tooltipGetMeasurements () {

				const	m = {
					elPos: o.useParentOffset ? $this.position() : $this.offset(),
					elWidth: $this.outerWidth(),
					elHeight: $this.outerHeight(),
					tooltipWidth: $thisTooltip.outerWidth(),
					tooltipHeight: $thisTooltip.outerHeight(),
					elToolDiffWidth: 0,
					elToolDiffHeight: 0,
					winSizeWidth: $window.width(),
					winSizeHeight: $window.height()
				};

				m.elToolDiffWidth = Math.abs(m.elWidth - m.tooltipWidth);
				m.elToolDiffHeight = Math.abs(m.elHeight - m.tooltipHeight);

				return m;

			}

			function addArrow (side) {

				$lpTooltipArrow[0].className = 'lpTooltipArrow';
				$lpTooltipArrow.css({
					borderBottom: `${o.arrowBorderWidth}px solid ${o.arrowBorderColor}`,
					borderRight: `${o.arrowBorderWidth}px solid ${o.arrowBorderColor}`,
					width: `${o.arrowSize}px`,
					height: `${o.arrowSize}px`,
					background: o.arrowColor
				});

				// rotate arrow to change direction
				if (side === 'top') {

					$lpTooltipArrow.addClass('sideTop');
					if (o.boxShadow.enabled) {

						$lpTooltipArrow.css('box-shadow', ''); // reset shadow that might be set previously from other side

					}

				} else if (side === 'bottom') {

					$lpTooltipArrow.addClass('sideBottom');
					if (o.boxShadow.enabled) {

						$lpTooltipArrow.css(
							'box-shadow',
							`${o.boxShadow.offsetX} 1px ${o.boxShadow.blurRadius} -1px ${o.boxShadow.shadowColor}`
						);

					}

				} else if (side === 'left') {

					$lpTooltipArrow.addClass('sideLeft');
					if (o.boxShadow.enabled) {

						$lpTooltipArrow.css('box-shadow', ''); // reset shadow that might be set previously from other side

					}

				} else if (side === 'right') {

					$lpTooltipArrow.addClass('sideRight');
					if (o.boxShadow.enabled) {

						$lpTooltipArrow.css(
							'box-shadow',
							`2px ${
								o.boxShadow.offsetY
							} ${
								o.boxShadow.blurRadius
							} 0 ${
								o.boxShadow.shadowColor}`
						);

					}

				}

			}

			function arrowPosition (axis, pos) {

				if ($lpTooltipArrow && $lpTooltipArrow.length) {

					if (axis === 'x') {

						if (o.horzAlign === 'left') {

							$lpTooltipArrow.css('right', pos).css('left', 'auto');

						} else {

							$lpTooltipArrow.css('left', pos).css('right', 'auto');

						}

					} else if (axis === 'y') {

						if (o.vertAlign === 'top') {

							$lpTooltipArrow.css('bottom', pos).css('top', 'auto');

						} else {

							$lpTooltipArrow.css('top', pos).css('bottom', 'auto');

						}

					}

				}

			}

			function isOutOfViewportYTop (y) {

				const
					$winScrollTop = $window.scrollTop();
				const viewResults = {};

				if (y < $winScrollTop) {

					viewResults.fits = false;
					viewResults.distToMoveDown = $winScrollTop - y + o.screenEdgePadding;
					return viewResults;

				}

				viewResults.fits = true;
				return viewResults;

			}

			function isOutOfViewportYBottom (y) {

				const
					$winScrollTop = $window.scrollTop();
				const $winHeight = $window.height();
				const viewResults = {};

				if (y > $winScrollTop + $winHeight) {

					viewResults.fits = false;
					viewResults.distToMoveUp = y - ($winScrollTop + $winHeight) + o.screenEdgePadding;
					return viewResults;

				}

				viewResults.fits = true;
				return viewResults;

			}

			function isOutOfViewportXLeft (x) {

				const
					$winScrollLeft = $window.scrollLeft();
				const viewResults = {};

				if (x < $winScrollLeft) {

					viewResults.fits = false;
					viewResults.distToMoveRight = $winScrollLeft - x + o.screenEdgePadding;
					return viewResults;

				}

				viewResults.fits = true;
				return viewResults;

			}

			function isOutOfViewportXRight (x) {

				const
					$winScrollLeft = $window.scrollLeft();
				const $winWidth = $window.width();
				const viewResults = {};

				if (x > $winScrollLeft + $winWidth) {

					viewResults.fits = false;
					viewResults.distToMoveLeft = x - ($winScrollLeft + $winWidth) + o.screenEdgePadding;
					return viewResults;

				}

				viewResults.fits = true;
				return viewResults;

			}

			function toolTipPosition (e) {

				// when called multiple times from window resize event, the $thisTooltip var references each tooltip that had been displayed before even if hidden
				// we only need to update the position of the currently visible tooltip
				if (e && isToolTipHidden($thisTooltip)) {

					return;

				}

				const
					m = tooltipGetMeasurements();
				const arrowBaseLength = Math.sqrt(Math.pow(o.arrowSize, 2) * 2); // the length of the base of triangle
				let arrowOffset = Math.round(arrowBaseLength / 2);

				// adjust arrow offset due to difference of height when arrow is rotated
				if (o.arrowBorderWidth) {

					if (o.arrowSize > 20) {

						arrowOffset -= 4;

					} else if (o.arrowSize > 12) {

						arrowOffset -= 2;

					}

				} else {

					if (o.arrowSize > 20) {

						arrowOffset -= 6;

					} else if (o.arrowSize > 12) {

						arrowOffset -= 4;

					} else {

						arrowOffset -= 2;

					}

				}

				if (o.arrow) {

					if (o.vertAlign === 'top' && o.horzAlign === 'center') {

						addArrow('bottom');

					} else if (o.vertAlign === 'bottom' && o.horzAlign === 'center') {

						addArrow('top');

					} else if (o.vertAlign === 'middle' && o.horzAlign === 'left') {

						addArrow('right');

					} else if (o.vertAlign === 'middle' && o.horzAlign === 'right') {

						addArrow('left');

					}

				}

				if (o.vertAlign === 'top') {

					const
						yTop = m.elPos.top - (m.tooltipHeight + o.offsetY);
					const inViewYTop = isOutOfViewportYTop(yTop);

					// if the tooltip can fit or if the space above the element is larger than or equal to the space below it
					if (
						inViewYTop.fits
						|| m.elPos.top - $window.scrollTop()
							>= Math.abs(Math.abs(m.elPos.top + m.elHeight - $window.scrollTop()) - m.winSizeHeight)
					) {

						$thisTooltip.css({
							top: `${yTop}px`
						});
						if (o.arrow) {

							arrowPosition('y', -arrowOffset);

						}

					} else {

						o.vertAlign = 'bottom';
						toolTipPosition();
						o.vertAlign = 'top';

					}

				}

				if (o.vertAlign === 'bottom') {

					const
						yBottom = m.elPos.top + m.elHeight + m.tooltipHeight + o.offsetY;
					const inViewYBottom = isOutOfViewportYBottom(yBottom);

					// if the tooltip can fit or if the space below the element is larger than or equal to the space above it
					if (
						inViewYBottom.fits
						|| m.elPos.top - $window.scrollTop()
							<= Math.abs(m.winSizeHeight - Math.abs(m.elPos.top + m.elHeight - $window.scrollTop()))
					) {

						$thisTooltip.css({
							top: `${m.elPos.top + (m.elHeight + o.offsetY)}px`
						});
						if (o.arrow) {

							arrowPosition('y', -arrowOffset);

						}

					} else {

						o.vertAlign = 'top';
						toolTipPosition();
						o.vertAlign = 'bottom';

					}

				}

				if (o.vertAlign === 'middle') {

					const
						yTop = m.elPos.top + m.elHeight / 2 - m.tooltipHeight / 2 + o.offsetY;
					const inViewYTop = isOutOfViewportYTop(yTop);
					const yBottom = m.elPos.top + m.elHeight / 2 + m.tooltipHeight / 2 + o.offsetY;
					const inViewYBottom = isOutOfViewportYBottom(yBottom);

					if ((inViewYTop.fits && inViewYBottom.fits) || (!inViewYTop.fits && !inViewYBottom.fits)) {

						$thisTooltip.css({
							top: `${m.elPos.top - m.elToolDiffHeight / 2 + o.offsetY}px`
						});
						if (o.arrow) {

							arrowPosition('y', m.tooltipHeight / 2 - arrowBaseLength / 2);

						}

					} else if (inViewYTop.fits && !inViewYBottom.fits) {

						$thisTooltip.css({
							top: `${m.elPos.top - m.elToolDiffHeight / 2 - inViewYBottom.distToMoveUp + o.offsetY}px`
						});
						if (o.arrow) {

							arrowPosition('y', m.tooltipHeight / 2 + inViewYBottom.distToMoveUp - o.arrowSize);

						}

					} else if (!inViewYTop.fits && inViewYBottom.fits) {

						$thisTooltip.css({
							top: `${m.elPos.top - m.elToolDiffHeight / 2 + inViewYTop.distToMoveDown + o.offsetY}px`
						});
						if (o.arrow) {

							arrowPosition('y', m.tooltipHeight / 2 - inViewYTop.distToMoveDown - o.arrowSize);

						}

					}

				}

				if (o.horzAlign === 'left') {

					const
						xLeft = m.elPos.left - m.tooltipWidth + o.offsetX;
					const inViewXLeft = isOutOfViewportXLeft(xLeft);

					// if the tooltip can fit or if the space to the left of the element is larger than or equal to the space to the right of the element
					if (
						inViewXLeft.fits
						|| m.elPos.left - $window.scrollLeft()
							>= Math.abs(m.winSizeWidth - Math.abs(m.elPos.left + m.elWidth - $window.scrollLeft()))
					) {

						$thisTooltip.css({
							left: `${m.elPos.left - (m.tooltipWidth + o.offsetX) - arrowOffset - 5}px`
						});
						if (o.arrow) {

							arrowPosition('x', -arrowOffset);

						}

					} else {

						o.horzAlign = 'right';
						toolTipPosition();
						o.horzAlign = 'left';

					}

				}

				if (o.horzAlign === 'right') {

					const
						xRight = m.elPos.left + m.elWidth + m.tooltipWidth + o.offsetX;
					const inViewXRight = isOutOfViewportXRight(xRight);

					// if the tooltip can fit or if the space to the left of the element is larger than or equal to the space to the right of the element
					if (
						inViewXRight.fits
						|| m.elPos.left - $window.scrollLeft()
							<= Math.abs(Math.abs(m.elPos.left + m.elWidth - $window.scrollLeft()) - m.winSizeWidth)
					) {

						$thisTooltip.css({
							left: `${m.elPos.left + (m.elWidth + o.offsetX) + arrowOffset + 5}px`
						});
						if (o.arrow) {

							arrowPosition('x', -arrowOffset);

						}

					} else {

						o.horzAlign = 'left';
						toolTipPosition();
						o.horzAlign = 'right';

					}

				}

				if (o.horzAlign === 'center') {

					const
						xLeft = m.elPos.left + m.elWidth / 2 - m.tooltipWidth / 2 + o.offsetX;
					const inViewXLeft = isOutOfViewportXLeft(xLeft);
					const xRight = m.elPos.left + m.elWidth / 2 + m.tooltipWidth / 2 + o.offsetX;
					const inViewXRight = isOutOfViewportXRight(xRight);

					if ((inViewXLeft.fits && inViewXRight.fits) || (!inViewXLeft.fits && !inViewXRight.fits)) {

						$thisTooltip.css({
							left: `${m.elPos.left - m.elToolDiffWidth / 2 + o.offsetX}px`
						});
						if (o.arrow) {

							arrowPosition('x', m.tooltipWidth / 2 - arrowBaseLength / 2);

						}

					} else if (inViewXLeft.fits && !inViewXRight.fits) {

						$thisTooltip.css({
							left: `${m.elPos.left - m.elToolDiffWidth / 2 + o.offsetX - inViewXRight.distToMoveLeft}px`
						});
						if (o.arrow) {

							arrowPosition('x', Math.min(m.tooltipWidth / 2 + inViewXRight.distToMoveLeft - o.arrowSize, $thisTooltip.outerWidth() - arrowBaseLength));

						}

					} else if (!inViewXLeft.fits && inViewXRight.fits) {

						$thisTooltip.css({
							left: `${m.elPos.left - m.elToolDiffWidth / 2 + o.offsetX + inViewXLeft.distToMoveRight}px`
						});
						if (o.arrow) {

							arrowPosition('x', Math.max(m.tooltipWidth / 2 - inViewXLeft.distToMoveRight - o.arrowSize, 2));

						}

					}

				}

				// fix arrow's vertical position bug - arrow goes out of bounds if element with tooltip is partially visible
				if (o.vertAlign === 'middle') {

					const
						topPos = parseInt($lpTooltipArrow.css('top'));
					const borderWidth = parseInt($thisTooltip.css('border-top-width'));
					const arrowOffsetTop = topPos + borderWidth; // offset top of arrow with respect to the tooltip container

					if (arrowOffsetTop < 0) {

						// arrow pointer out of bounds at the top
						window.scrollBy(0, arrowOffsetTop);
						$thisTooltip.css('top', parseInt($thisTooltip.css('top')) + arrowOffsetTop);
						arrowPosition('y', parseInt($lpTooltipArrow.css('top')) - arrowOffsetTop);

					} else {

						const
							arrowOffsetHeight = topPos + o.arrowSize * 2;
						const tooltipOffsetHeight = $thisTooltip.height() + parseInt($thisTooltip.css('border-bottom-width'));
						const arrowOffsetBottom = tooltipOffsetHeight - arrowOffsetHeight;

						if (arrowOffsetBottom < 0) {

							// arrow pointer out of bounds at the bottom
							window.scrollBy(0, -arrowOffsetBottom);
							$thisTooltip.css('top', parseInt($thisTooltip.css('top')) - arrowOffsetBottom);
							arrowPosition('y', parseInt($lpTooltipArrow.css('top')) + arrowOffsetBottom);

						}

					}

				}

			}

			function init () {

				$thisTooltip.addClass('showUp');
				$thisTooltip.removeClass('forceClose');
				$window.on('resize.lpTooltip', toolTipPosition);

				if (o.autoClose) {

					if (isIpad()) {
						document.addEventListener('touchstart', CloseOnIpad, { passive: true });
					}

					if (o.closeOnTooltipBtnBlur) {

						if (o.keyboardNavigationTriggerElement) {
				
							$(o.keyboardNavigationTriggerElement).on('blur', closeTooltipOnBlur);

							if (o.showCloseBtn) {

								$thisTooltip.find('.close').on('blur', closeTooltipOnBlur);

							}
		
						} else {

							document.addEventListener('click', documentClick, true);

						}

					}

				}

			}

			function cleanUp () {

				$thisTooltip.removeClass('showUp');
				$window.off('resize.lpTooltip', toolTipPosition);
				if (o.autoClose) {

					if (isIpad()) {
						document.removeEventListener('touchstart', CloseOnIpad);
					}

					if (o.closeOnTooltipBtnBlur) {

						if (o.keyboardNavigationTriggerElement) {
				
							$(o.keyboardNavigationTriggerElement).off('blur', closeTooltipOnBlur);
	
							if (o.showCloseBtn) {
	
								$thisTooltip.find('.close').off('blur', closeTooltipOnBlur);
	
							}
		
						} else {

							document.removeEventListener('click', documentClick, true);
		
						}

					}

				}

			}

			function isElementOutsideTooltip (el) {

				return !el.closest('.haslpt, .toolTipInfo') && $(el).closest($thisTooltip).length === 0;

			}

			function OpenToolTip (e) {

				// if clicking inside tooltip
				if ($(e.target).closest('.showUp').length > 0) {

					return;

				}
				e.preventDefault();

				if (o.preOpenEvt($this)) {

					$this.trigger('beforeOpen.lpTooltip');
					if (!o.autoClose) {

						// click other visible tooltip (if any)
						$('body .lpTooltip')
							.filter((i, el) => isToolTipVisible($(el)))
							.trigger('forceClose.lpTooltip');

					}

					toolTipPosition();
					$thisTooltip.show();
					$thisTooltip.css('pointer-events', 'auto');
					init();
					o.openEvt($this);

				}

			}

			function CloseOnIpad (e) {

				if (isElementOutsideTooltip(e.target)) {

					CloseToolTip(e, true);

				}

			}

			function ForceClose (e) {

				e.stopPropagation();
				$thisTooltip.addClass('forceClose');
				CloseToolTip(e);

			}

			function documentClick (e) {
	
				if (e.target !== $this) {
				
					closeTooltipOnBlur(e, e.target);
					
				}
					
			}

			function CloseToolTip (e, fromIpad = false) {

				if (!fromIpad) {
					e.preventDefault();
				}
				$this.trigger('beforeClose.lpTooltip');
				cleanUp();
				afterClosing();

			}

			function openTooltipOnEnter (e) {

				if (e.key === 'Enter' || e.keyCode === 13) {
		
					$this.trigger('mouseenter');
	
				}

			}

			function closeTooltipOnBlur (e, target) {

				// set a little delay to get the new active element
				setTimeout(() => {

					const { activeElement } = document;
					if (isElementOutsideTooltip(target || activeElement)) {

						CloseToolTip(e);

					}

				}, 20);

			}

			function afterClosing () {

				$thisTooltip.hide();
				$thisTooltip.css('pointer-events', 'none');
				o.closeEvt($this);
				$this.trigger('afterClose.lpTooltip');

			}

			function afterShowing () {

				o.openEvt($this);
				$this.trigger('afterOpen.lpTooltip');

			}

			if (o.evtType === 'click') {

				$this.on('click', OpenToolTip);

			} else if (o.evtType === 'hover') {

				$this.on('mouseenter', OpenToolTip);
				$this.on('mouseleave', CloseToolTip);

				if (o.keyboardNavigationTriggerElement) {
				
					$(o.keyboardNavigationTriggerElement).on('keydown', openTooltipOnEnter);

				}

			}

			if (o.showCloseBtn) {

				const $closeBtn = $thisTooltip.find('.close');
				$closeBtn.on('click', (e) => {
					
					ForceClose(e);

					if (o.keyboardNavigationTriggerElement) {

						o.keyboardNavigationTriggerElement.focus();

					}
					
				});

			}

			const observer = new MutationObserver((mutationList) => {

				if (openStatus($thisTooltip)) {

					for (let i = 0; i < mutationList.length; i++) {

						if (
							mutationList[i].type === 'childList'
								&& mutationList[i].removedNodes
								&& Array.prototype.some.call(mutationList[i].removedNodes, (el) => el === $thisTooltip[0])
						) {

							openStatus($thisTooltip, false);
							cleanUp();

						}

					}

				}

			});
			observer.observe($thisTooltip[0].parentElement, { childList: true });

			$thisTooltip.on('mousedown', (e) => e.stopPropagation());
			openStatus($thisTooltip, false);
			$thisTooltip.on('transitionend', () => {

				if (openStatus($thisTooltip) && isToolTipHidden($thisTooltip)) {

					openStatus($thisTooltip, false);
					afterClosing();

				}
				if (!openStatus($thisTooltip) && isToolTipVisible($thisTooltip)) {

					openStatus($thisTooltip, true);
					afterShowing();

				}

			});

		});

		return this;

	};

}(jQuery));
